import get from 'lodash/get';
import {
  GIFTS_CATEGORY_NAME,
  BG_PROD_URL,
  BG_DEVINT_URL,
  BG_STAGE_URL,
} from 'storefront/components/constants';
import { isMobile } from 'client-utils/utilities-page';

export const isGiftCategory = (silo, giftSiloToggle) => {
  return giftSiloToggle && get(silo, 'name') === GIFTS_CATEGORY_NAME;
};

export const getLinkDomain = () => {
  let hostName = BG_PROD_URL;
  const envStartsWith = prefix =>
    (process.env.ENV_NAME || process.env.NMG_ENV || '')
      .toLowerCase()
      .startsWith(prefix);

  if (envStartsWith('bgprod')) {
    hostName = BG_PROD_URL;
  } else if (envStartsWith('bgprep')) {
    hostName = BG_STAGE_URL;
  } else if (envStartsWith('bgdevint')) {
    hostName = BG_DEVINT_URL;
  }

  if (isMobile()) {
    hostName = `https://${window?.location?.host}` || BG_PROD_URL;
  }

  return hostName;
};
