import React, { useLayoutEffect, useEffect, useState } from 'react';
import { SHIP_FORM_VENDOR_STATUS } from '../../constants';

const KlarnaMessaging = ({ price, selectedSku }) => {
  const [isKlarnaLoaded, setKlarnaLoaded] = useState(false);
  const isPreOrder =
    selectedSku?.preOrder &&
    selectedSku?.stockStatusMessage !== SHIP_FORM_VENDOR_STATUS;

  const isDropship =
    selectedSku?.stockStatusMessage === SHIP_FORM_VENDOR_STATUS &&
    selectedSku?.dropShip;

  const isBackOrder =
    selectedSku?.backOrder &&
    (selectedSku.expectedShipDays > 0 ||
      selectedSku.expectedShipDays === 'U') &&
    !selectedSku.preOrder;

  useLayoutEffect(() => {
    const style = document?.createElement('style');
    style.innerHTML = `
      klarna-placement::part(osm-container) {
        text-align: left;
        padding-left: 0;
        font-size: 14px;
        padding-bottom: 6px;
        letter-spacing: 0.14px;
        font-weight: 400;
        font-family: Larsseit;
        border: 0;
      }
      #pdp-klarna::part(osm-cta) {
        font-family: Larsseit;
        font-size: 13.3px
      }
    `;
    document?.head?.appendChild(style);
    setKlarnaLoaded(true);
    return () => {
      document?.head?.removeChild(style);
    };
  }, []);

  useEffect(() => {
    window?.Klarna?.OnsiteMessaging?.refresh();
  }, [price]);

  return isKlarnaLoaded && !isPreOrder && !isDropship && !isBackOrder ? (
    <klarna-placement
      id="pdp-klarna"
      data-key="credit-promotion-auto-size"
      data-locale="en-US"
      data-purchase-amount={Number(price?.replace(/[ ,.\s]/g, ''))}
    />
  ) : null;
};

export default KlarnaMessaging;
