/* eslint-disable consistent-return */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { localStorage } from 'window-or-global';
import {
  updateSearchTerm,
  setSearchTermToEmpty,
  setPlaceHolderValueToEmpty,
  setSearchTermValueFromPlaceholder,
  closeSearchPanel,
  clearAutosuggestion,
} from '../search-actions';
import {
  getSearchTerm,
  getSearchVisible,
  getAutoSuggestionList,
} from '../search-reducers';
import searchFormSubmit from '../submitHelper';
import { saveToLocalStorage } from '../../../../client/utilities/utilities-storage';

import './searchPanel.scss';

class SearchPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      cursor: 0,
      searchPlaceholder:
        this.props.isRecentSearchOn &&
        this.props.location.pathname === '/search/' &&
        this.props.location?.query?.q,
      showSearchHistory: this.props.isRecentSearchOn,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isVisible && this.props.isVisible) {
      // add global show-mobile-search class (may be unused, but leaving it for compatibility reasons)
      const contentContainer = document.getElementById('contentContainer');
      if (contentContainer) {
        contentContainer.classList.add('show-mobile-search');
      }

      // focus input on open has been moved to button component for compatibility with Safari)
    } else if (prevProps.isVisible && !this.props.isVisible) {
      // remove global show-mobile-search class
      const contentContainer = document.getElementById('contentContainer');
      if (contentContainer) {
        contentContainer.classList.remove('show-mobile-search');
      }
    }
  }

  focusInput = () => {
    if (this.inputField) {
      this.inputField.focus();
    }
  };

  clearSearchTerm = () => {
    const {
      clearSearchTerm,
      clearPlaceHolder,
      clearAutosuggestion,
    } = this.props;
    this.setState({
      showSearchHistory:
        this.props.isRecentSearchOn &&
        localStorage?.getItem('searchHistory') !== null,
      searchPlaceholder: this.state.searchPlaceholder && null,
    });

    clearSearchTerm('', true);
    clearAutosuggestion();
    clearPlaceHolder();
    this.focusInput();
  };

  closeSearchPanel = () => {
    const { closeSearch } = this.props;

    this.clearSearchTerm();
    closeSearch();
  };

  renderSearchSuggestion = (searchTerm, value = '') => {
    const searchReplace = value?.replace(searchTerm, ',<keyword>,');
    const searchAutoSuggest = [];
    const splittedValue = searchReplace?.split(',') || [];
    splittedValue.forEach(searchData => {
      if (searchData !== '') {
        if (searchData === '<keyword>') {
          searchAutoSuggest.push(
            <span style={{ fontWeight: 'bold' }}>{searchTerm}</span>,
          );
        } else {
          searchAutoSuggest.push(<span>{searchData}</span>);
        }
      }
    });
    return searchAutoSuggest;
  };

  saveSearchHistory = searchHistoryTerm => {
    this.closeSearchPanel();
    const searchHistory = localStorage?.getItem('searchHistory') || ' ';
    const searchHistoryArray = searchHistory?.split('|');
    if (searchHistory) {
      this.setState({
        showSearchHistory: true,
      });
      if (searchHistoryTerm && searchHistoryTerm !== ' ') {
        if (searchHistory !== ' ' && searchHistoryArray?.length > 0) {
          if (searchHistoryArray.includes(searchHistoryTerm)) {
            if (searchHistory === searchHistoryTerm) {
              saveToLocalStorage('searchHistory', searchHistoryTerm);
            } else {
              const updatedSearchHistoryArray = searchHistoryArray.filter(
                item => item !== searchHistoryTerm,
              );
              let updatedSearchHistory = searchHistoryTerm;
              updatedSearchHistoryArray.forEach(item => {
                updatedSearchHistory += `|${item}`;
                return updatedSearchHistory;
              });
              saveToLocalStorage('searchHistory', updatedSearchHistory);
            }
          } else if (searchHistoryArray?.length < 5) {
            const updatedSearchHistory = `${searchHistoryTerm}|${searchHistory}`;
            saveToLocalStorage('searchHistory', updatedSearchHistory);
          } else {
            let updatedSearchHistory = searchHistory.substring(
              0,
              searchHistory.lastIndexOf('|') + 1,
            );
            updatedSearchHistory = updatedSearchHistory.slice(0, -1);
            updatedSearchHistory = `${searchHistoryTerm}|${updatedSearchHistory}`;
            saveToLocalStorage('searchHistory', updatedSearchHistory);
          }
        } else {
          const updatedSearchHistory = searchHistoryTerm;
          saveToLocalStorage('searchHistory', updatedSearchHistory);
        }
      }
    }
  };

  sendSuggestClickEvent = suggestedSearchTerm => {
    const { searchTerm } = this.props;
    const searchData = {
      q: searchTerm,
      aq: suggestedSearchTerm,
    };
    window?.BrTrk?.getTracker().logEvent(
      'suggest',
      'click',
      searchData,
      {},
      true,
    );
  };

  handleSuggestionClick = (suggestedSearchTerm, position) => {
    const { vertexAiToggle, autoSuggestionList } = this.props;
    if (vertexAiToggle && autoSuggestionList?.completionAttributionToken) {
      window?.sessionStorage?.setItem(
        'completion_token',
        autoSuggestionList?.completionAttributionToken,
      );
      position > -1 &&
        window?.sessionStorage?.setItem('searchTypeAheadPosition', position);
    }

    this.saveSearchHistory(suggestedSearchTerm);
    this.sendSuggestClickEvent(suggestedSearchTerm);
  };

  renderSearchHistory = () => {
    const { cursor } = this.state;
    const searchHistory = localStorage?.getItem('searchHistory')?.split('|');
    if (searchHistory?.length > 0) {
      return (
        this.props.isRecentSearchOn && (
          <div className="search-history">
            <div className="flex-grid search-history-header">
              <h2> Recent Searches </h2>
              <button onClick={() => this.clearSearchHistory()}>
                Clear History
              </button>
            </div>
            <div className="search-history-list">
              <ul>
                {searchHistory?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      tabIndex={index}
                      className={cursor === index ? 'active' : null}
                      onClick={() => this.handleSearchHistoryClick(item)}
                    >
                      <span> {item} </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )
      );
    }
  };

  clearSearchHistory = () => {
    localStorage?.removeItem('searchHistory');
    this.setState({
      showSearchHistory: false,
    });
  };

  handleSearchHistoryClick = searchHistoryItem => {
    this.saveSearchHistory(searchHistoryItem);
    if (searchHistoryItem !== '') {
      const nmObj = window?.nm;
      const recentSearchUrl = `/search/?from=brSearch&responsive=true&request_type=search&search_type=keyword&q=${searchHistoryItem}&l=${searchHistoryItem}&fl=`;
      if (nmObj?.om?.appendCookie && nmObj?.omPreviousPageCookieName) {
        const prevLink = JSON.stringify({
          prev_page_link: 'recently searched',
          prev_link_type: 'search',
        });
        nmObj.om.appendCookie(nmObj.omPreviousPageCookieName, prevLink, 0, '/');
      }
      this.setState({
        searchPlaceholder:
          this.props.location.pathname === '/search/' && searchHistoryItem,
      });
      window.location.href = recentSearchUrl;
    }
  };

  handleSearchInputChange = searchTerm => {
    this.props.isRecentSearchOn &&
      this.setState({
        searchPlaceholder: null,
        showSearchHistory: searchTerm === '',
      });
    this.props.handleOnChange(searchTerm, true, this.props.vertexAiToggle);
  };

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      this.saveSearchHistory(e?.target?.value);
      return;
    }

    const { cursor, showSearchHistory } = this.state;
    let searchHistory = localStorage?.getItem('searchHistory');
    searchHistory = searchHistory?.split('|') || [];

    if (showSearchHistory) {
      if (e.keyCode === 38 && cursor > 0) {
        this.setState(prevState => ({
          cursor: prevState.cursor - 1,
          searchPlaceholder: searchHistory[cursor - 1],
        }));
      } else if (e.keyCode === 40 && cursor < searchHistory.length - 1) {
        this.setState(prevState => ({
          cursor: prevState.cursor + 1,
          searchPlaceholder: searchHistory[cursor + 1],
        }));
      }
    }
  }

  renderClearSearchTerm = searchTerm => {
    if (searchTerm || this.state.searchPlaceholder) {
      return (
        <div
          role="button"
          aria-label="Clear search input"
          className="search-panel__clear-btn"
          tabIndex="0"
          onClick={this.clearSearchTerm}
        >
          clear
        </div>
      );
    }
  };

  handleFormSubmit(e) {
    e.preventDefault();
    const { searchTerm } = this.props;
    const encodedSearchTerm = encodeURIComponent(searchTerm);
    if (searchTerm) {
      searchFormSubmit(e, this.saveSearchHistory, searchTerm);
      window.location.href = `/search/?N=0&Ntt=${encodedSearchTerm}&from=brSearch&q=${encodedSearchTerm}`;
    }
  }

  render() {
    const {
      isVisible,
      searchTerm,
      autoSuggestionList,
      vertexAiToggle,
      bgVertexAiToggle,
    } = this.props;
    const { searchPlaceholder } = this.state;
    const classVisible = isVisible ? 'search-panel-container--open' : '';
    const suggestionsLimit = bgVertexAiToggle ? 10 : 7;

    let autoSuggestions = autoSuggestionList;
    if (vertexAiToggle) {
      autoSuggestions = autoSuggestionList?.suggestions || [];
    }

    return (
      <div className={`search-panel-container ${classVisible}`}>
        <div
          className="search-panel-container__overlay"
          onClick={this.closeSearchPanel}
        />
        <form
          name="searchBox"
          className="search-panel"
          onSubmit={this.handleFormSubmit}
        >
          <div className="input-and-button rel_box">
            <div className="make-relative search-panel__typeahead-container">
              {/* searchResponsive.js uses "make-relative" class inside "input-and-button" for binding */}
              <input type="hidden" title="endeca-N-input" name="N" value="0" />
              <input
                type="hidden"
                title="endeca-NTT-input"
                name="Ntt"
                value={searchTerm}
              />
              <input
                type="hidden"
                title="from-input"
                name="from"
                value="brSearch"
              />
              <input
                type="text"
                title="Search box"
                autoComplete="off"
                id="brSearchInput"
                name="q"
                className="search-panel__text"
                value={
                  this.props.isRecentSearchOn && searchPlaceholder
                    ? searchPlaceholder
                    : searchTerm
                }
                onChange={e =>
                  this.handleSearchInputChange(
                    e.target.value,
                    true,
                    vertexAiToggle,
                  )}
                onKeyUp={e => this.handleKeyDown(e)}
                alt="Search"
                placeholder={
                  'Search' || (this.props.isRecentSearchOn && searchPlaceholder)
                }
                ref={elem => {
                  this.inputField = elem;
                }}
              />
              <input
                type="submit"
                title="Submit button"
                value="Search"
                className="search-panel__submit headerSearchButton"
              />
              <input
                type="reset"
                name="reset-search"
                title="reset-search"
                value="_"
                className="icon icon--close search-panel__close"
                aria-label="Close search"
                onClick={this.closeSearchPanel}
              />
              {this.renderClearSearchTerm(searchTerm)}
              {this.state.showSearchHistory && this.renderSearchHistory()}
              {searchTerm?.length > 2 && (
                <div className="auto-suggest-wrap">
                  <ul>
                    {autoSuggestions
                      ?.slice(0, suggestionsLimit)
                      .map((value, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            this.handleSuggestionClick(
                              vertexAiToggle && !value.q ? value : value.q,
                              vertexAiToggle && index + 1,
                            )}
                        >
                          <a
                            href={`/search/?from=brSearch&responsive=true&request_type=search&search_type=keyword&q=${
                              vertexAiToggle && !value.q ? value : value.q
                            }`}
                          >
                            <div>
                              {this.renderSearchSuggestion(
                                searchTerm,
                                vertexAiToggle && !value.q ? value : value.q,
                              )}
                            </div>
                          </a>
                        </li>
                      ))}
                  </ul>
                  {autoSuggestions[0]?.filters && (
                    <ul className="categories-wrap">
                      <li className="category-title"> Categories </li>
                      {autoSuggestions[0]?.filters?.map((value, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            this.handleSuggestionClick(
                              `${searchTerm} in ${value.name}`,
                            )}
                        >
                          <a
                            href={`/search/?from=brSearch&responsive=true&request_type=search&search_type=keyword&q=${searchTerm} in ${value.name}`}
                          >
                            <b>{searchTerm}</b> in {value?.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              <div className="br-search-static" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchTerm: getSearchTerm(state),
  isVisible: getSearchVisible(state),
  autoSuggestionList: getAutoSuggestionList(state),
  isRecentSearchOn: state.toggles.RECENT_SEARCHES,
  location: state.page.location,
  vertexAiToggle:
    state?.toggles?.BG_VERTEX_AI ||
    (get(state, 'toggles.ABTEST_VERTEXAI_SRP', false) &&
      get(state, `abTestsOpt.tl364.variation`, 'a') === 'b') ||
    false,
  bgVertexAiToggle: state?.toggles?.BG_VERTEX_AI,
});

const mapDispatchToProps = {
  handleOnChange: updateSearchTerm,
  handleOnFocus: setSearchTermValueFromPlaceholder,
  clearSearchTerm: setSearchTermToEmpty,
  clearPlaceHolder: setPlaceHolderValueToEmpty,
  closeSearch: closeSearchPanel,
  clearAutosuggestion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPanel);
