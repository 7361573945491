import { saveToLocalStorage } from './utilities-storage';

const KMSI_KEY = '_kmsi';
const KMSI_SESSION_KEY = '_kmsi_session';
const VALUE_TRUE = 'true';
const VALUE_FALSE = 'false';

/**
 * Get current status of Keep Me Signed In feature. Defaults to false if not set.
 * @returns {boolean}
 */
export const getKmsiStatus = () => {
  const kmsi = window.localStorage.getItem(KMSI_KEY);
  return kmsi === VALUE_TRUE;
};

export const isKmsiSet = () => {
  const kmsi = window.localStorage.getItem(KMSI_KEY);
  return kmsi === VALUE_TRUE || kmsi === VALUE_FALSE;
};

export const getKmsiSessionStatus = () => {
  return (
    getKmsiStatus() ||
    window.sessionStorage.getItem(KMSI_SESSION_KEY) === VALUE_TRUE
  );
};

/**
 * Set current status of Keep Me Signed In feature.
 * @param kmsiStatus {boolean} true or false
 */
export const setKmsiStatus = kmsiStatus => {
  saveToLocalStorage(KMSI_KEY, (!!kmsiStatus).toString());
  if (!kmsiStatus) {
    window.sessionStorage.setItem(KMSI_SESSION_KEY, VALUE_TRUE);
  }
};

export const deleteKmsiStatus = () => {
  window.localStorage.removeItem(KMSI_KEY);
};
