import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

const Video = props => {
  const videoRef = useRef(null);
  const { isMobile, isActive, videoSrc, posterSrc } = props;
  useEffect(() => {
    if (isActive) {
      if (isMobile) {
        videoRef.current.currentTime = 0;
        videoRef.current.muted = true;
      }
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isActive, isMobile, videoSrc]);

  return (
    <video
      height="100%"
      width="100%"
      style={{ 'object-fit': 'cover' }}
      controls
      muted
      preload="none"
      playsInline
      ref={videoRef}
      poster={posterSrc}
    >
      <source src={videoSrc} type="video/mp4" />
      Video is not supported in this browser.
    </video>
  );
};

export default connect()(Video);
