import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './banner.scss';
import { SubTitle2, BodyB1 } from 'bgo-common/Styleguide/Typography';
import closeSrc from '../../assets/images/close_light.svg';
import appIcon from '../../assets/images/app-icons/appIcon@2x.png';
import {
  isIphoneNonSafari,
  isMobile,
} from '../../client/utilities/utilities-page';
import { saveToLocalStorage } from '../../client/utilities/utilities-storage';

class DownloadappBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppBannerFlag: false,
      isIphoneNonSafari: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    let isAppBannerFlag = false;
    if (window && window.localStorage) {
      isAppBannerFlag = window.localStorage.getItem('isAppBanner') === 'true';
    }

    this.setState({
      isAppBannerFlag,
      isIphoneNonSafari: isIphoneNonSafari(),
      isMobile: isMobile(),
    });
  }

  closeAppBanner = () => {
    saveToLocalStorage('isAppBanner', 'true');

    this.setState({
      isAppBannerFlag: true,
    });
  };

  render() {
    const { isIphoneNonSafari, isMobile, isAppBannerFlag } = this.state;
    const { mobileAppBanner, mobileAppDownloadLink } = this.props;
    const showBanner = isIphoneNonSafari && isMobile && !isAppBannerFlag;
    return (
      showBanner && (
        <div id="banner" className="download-app-banner">
          <div className="content">
            <div className="close">
              <a onClick={this.closeAppBanner}>
                <img
                  className="icon-close"
                  alt="Close icon"
                  aria-label="Closing Banner"
                  src={closeSrc}
                />
              </a>
            </div>
            <div className="logo">
              <img src={appIcon} alt="Bergdorf Goodman" />
            </div>
            <div className="description">
              <SubTitle2>
                <span className="title">{mobileAppBanner.title}</span>
              </SubTitle2>
              <BodyB1>
                <span className="desc">{mobileAppBanner.subTitle}</span>
              </BodyB1>
            </div>
          </div>
          <div className="get-link" onClick={this.closeAppBanner}>
            <a
              href={mobileAppDownloadLink}
              // target="_blank"
              rel="noreferrer noopener"
            >
              GET
            </a>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  mobileAppDownloadLink: get(state, 'mobileAppDownloadLink.ios', ''),
  mobileAppBanner: state.mobileAppBanner,
});
export default connect(mapStateToProps)(DownloadappBanner);
