import Badges from 'shared/components/Badges/badges';
import {
  BodyB1,
  EyeBrow1,
  EyeBrow2,
  EyeBrow4,
  EyeBrow7,
} from 'bgo-common/Styleguide/Typography';
import classnames from 'classnames';
import { unescape } from 'he';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import BopsButton from 'pdp/components/ProductPage/optimizationcomponents/BopsButton/bopsButton';
import DropDownComponent from 'pdp/components/ProductPage/optimizationcomponents/DropDownComponent/dropDownComponent';
import FavComponent from 'pdp/components/ProductPage/optimizationcomponents/FavoriteComponent/favoriteComponent';
import PersonalizeItemButton from 'pdp/components/ProductPage/optimizationcomponents/PersonalizeItemButton/PersonalizeItemButton';
import ProductOptionSet from 'pdp/components/ProductPage/optimizationcomponents/ProductOptionSet/productOptionSet';
import 'pdp/components/ProductPage/optimizationcomponents/productQuantity/productQuantity.scss';
import ProductSize from 'pdp/components/ProductPage/optimizationcomponents/ProductSize/productSize';
import {
  BEAUTY_SPECIALIST,
  GO_TO_ITEM_PAGE,
  GWP_TEXT,
  PERSONALIZATION_REQUIRED,
  PERSONALIZATION_REQUIRED_TEXT,
  PRECIOUS_JEWELRY_PAYPAL_MESSAGE,
  REQUIRED_MONOGRAM,
  BUCKLE_FINISH,
} from 'pdp/constants';
import {
  openReplenishment,
  setHoveredIndex,
  setReplenishInterval,
  setSelectedIndex,
  checkIfApplePayIsEligible,
  addToBag,
  showAddToBagError,
  clearError,
  getUserData,
} from 'productpage/actions';
import AddToBagButton from 'productpage/components/AddToBagButton/addToBagButton';
import BeautyReplenishment from 'productpage/components/BeautyReplenishment/beautyReplenishment';
import BossMessage from 'productpage/components/BossMessage/bossMessage';
import GiftNow from 'productpage/components/GiftNow/giftNow';
import PerishableMessage from 'productpage/components/Perishables/perishableMessage';
import ProductQuantity from 'productpage/components/ProductQuantity/productQuantity';
import SkuStatusMessages from 'productpage/components/SkuStatusMessages/skuStatusMessages';
import Personalization from 'productpage/optimizationcomponents/Monogram/personalization';
import getColorSkus from 'productpage/selectors/getColorSkus';
import { getSelectedOptionMedia } from 'productpage/selectors/getMedia';
import screenReaderAnnouncer from 'bgo-common/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import {
  getColorOptions,
  getSizeOptions,
} from 'productpage/selectors/getOptions';
import getSelectedBuckleFinishIndex from 'productpage/selectors/getSelectedBuckleFinishIndex';
import getSelectedColorIndex from 'productpage/selectors/getSelectedColorIndex';
import getSelectedSku from 'productpage/selectors/getSelectedSku';
import getSizeSkus from 'productpage/selectors/getSizeSkus';
import React, { Fragment, useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';
import SaveToWishlistLink from '../../../../../wishlist/components/WishlistPage/components/Wishlist/saveToWishlist';
import ShopNowButton from '../../components/ShopNow/shopNow';
import ErrorMessage from '../../components/ErrorMessage/errorMessage';
import './checkoutOptionInfo.scss';
import ApplePay from '../../../ApplePay/ApplePay';
import { checkClientSupportForApplePayment } from '../../../ApplePay/ApplePay.utils';
import validatePDP, { ADD_TO_CART } from '../../pdpPageValidator';
import { isMobileWebPdpRedesign } from '../../../../../../../../../common/client/common/actions/actions-page';
import AffirmCta from '../../../AffirmCta/AffirmCta';
import KlarnaMessaging from '../../../KlarnaMessage/KlarnaMessage';

const PerishableCalendar = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'perishable-calendar' */ 'productpage/components/Perishables/perishableCalendar'
    ),
  loading: () => false,
});

const CheckoutOptionInfo = props => {
  const {
    product,
    boss = {},
    hideFromEditWishlist = true,
    isFromShopTheLook = false,
    zeroDollarPageAllBeautyLink,
    wishlistValidationErrors = {},
    isNewCartCheckoutEnabled,
    api,
    isFromQL = false,
    getUserData,
  } = props;
  const [isApplePaySupported, setApplePaySupport] = useState(false);
  const [isApplePayAuthorized, setApplePayAuthorized] = useState(false);

  let deliveryDate = null;
  if (product.perishable && !hideFromEditWishlist && props.deliveryDate) {
    deliveryDate = props.deliveryDate;
  }

  const checkApplePaySupport = () => {
    const { applePayToggle } = props;
    const isApplePaySupported =
      applePayToggle && checkClientSupportForApplePayment();
    setApplePaySupport(isApplePaySupported);
    if (isApplePaySupported) {
      props.getUserData();
    }
  };

  useEffect(() => {
    checkApplePaySupport();
    if (window?.sessionStorage?.getItem('ApplePayPDP')) {
      window?.sessionStorage?.removeItem('ApplePayPDP');
    }
  }, []);

  const behaviorCode = get(product, 'customization.behaviorCode');
  const productState = product;
  const QlProduct = productState?.product;
  const colorOptions = getColorOptions(productState);
  const sizeOptions = getSizeOptions(productState);
  const selectedColorIndex = getSelectedColorIndex(productState);
  const selectedBuckleFinishIndex = getSelectedBuckleFinishIndex(productState);
  const selectedOptionMedia = getSelectedOptionMedia(productState);
  const selectedSizeIndex = get(productState, 'options.selectedSizeIndex', -1);
  const colorSkus = getColorSkus(productState, selectedSizeIndex);
  const sizeSkus = getSizeSkus(productState, selectedColorIndex);
  const noAvailableSizeSelected = selectedSizeIndex > 0 && isEmpty(colorSkus);
  const appendKey = 'productButtons';
  const canonicalUrl = get(productState, 'details.canonicalUrl', '');
  const productCategory = get(product, 'hierarchy[0].level1', '');
  const isGiftProduct = !(product?.price?.retailPrice > 0);
  const isNewPDP =
    props.pdpOptimisationToggle &&
    !product.customizationSupported &&
    product.displayable &&
    !product.isChanel &&
    !product.isZeroDollarProduct;

  const isR2Enabled = props.release2;

  const selectedSku = getSelectedSku(
    { colorSkus, sizeSkus },
    { colorOptions, sizeOptions },
    { selectedColorIndex, selectedSizeIndex },
    isR2Enabled,
  );
  const hoveredColorIndex = get(productState, 'options.hoveredColorIndex', -1);
  const hoveredSizeIndex = get(productState, 'options.hoveredSizeIndex', -1);
  const hoveredBuckleIndex = get(
    productState,
    'options.hoveredBuckleFinishIndex',
    -1,
  );
  const { details = {} } = product;
  const { parentheticalCharge = {} } = details;

  // const shortDesc = details.shortDesc;

  const isPreciousJewelry = () => {
    return get(product, 'details.preciousJewelryItem', false);
  };

  const isRequiredMonogram = () => {
    return behaviorCode === REQUIRED_MONOGRAM;
  };
  const isPersonalized = () => {
    return product.isPersonalizationSelected;
  };

  const isCustomizationOptionTypePDP = () => {
    let isCustomOptionPDP = false;
    if (product.customization && product.customization.customizationOptions) {
      isCustomOptionPDP = true;
      product.customization.customizationOptions.forEach(element => {
        if (element.type !== 'PDP') {
          isCustomOptionPDP = false;
        }
      });
    }
    return isCustomOptionPDP;
  };

  const isRequiredMonogramNotCustomized = () => {
    return (
      product.customizationSupported &&
      isRequiredMonogram() &&
      !isPersonalized()
    );
  };

  const shouldShowPersonalizeSection = () => {
    return product.customizationSupported && !isRequiredMonogramNotCustomized();
  };
  const quantityEnabled =
    props.hasQuantity !== undefined ? props.hasQuantity : true;

  const handleApplePay = () => {
    if (selectedSku) {
      props.clearError();
      return false;
    } else {
      const errorMessage = validatePDP(
        product,
        selectedSku,
        '',
        ADD_TO_CART,
        isCustomizationOptionTypePDP(),
        isNewPDP,
      );
      if (errorMessage) {
        props.showAddToBagError(errorMessage);
        props.screenReaderAnnouncer(errorMessage);
      }
      return errorMessage;
    }
  };

  const applePayAuthorized = () => {
    setApplePayAuthorized(true);
  };

  const dtAddtoBagButton = get(
    api,
    `add_to_bag_${selectedSku?.id}.loading`,
    false,
  ) ? (
    <div className="add-to-cart__loader-wrapper">
      <div className="add-to-cart__loader" />
    </div>
  ) : (
    <AddToBagButton
      productId={product.id}
      selectedSku={selectedSku}
      customizationOptionTypePDP={isCustomizationOptionTypePDP()}
      skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
      isNewPDP={isNewPDP}
      quantityEnabled={quantityEnabled}
      showLoading
      isFromQL={isFromQL}
      productDetails={product}
    />
  );

  const atgAddToBagButton = (
    <AddToBagButton
      productId={product.id}
      selectedSku={selectedSku}
      customizationOptionTypePDP={isCustomizationOptionTypePDP()}
      skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
      isNewPDP={isNewPDP}
      quantityEnabled={quantityEnabled}
      isFromQL={isFromQL}
      productDetails={product}
    />
  );

  /* eslint-disable no-nested-ternary */
  const addToBagButton =
    isFromQL && isGiftProduct
      ? null
      : isNewCartCheckoutEnabled
      ? dtAddtoBagButton
      : atgAddToBagButton;

  const giftNowButton = <GiftNow product={product} selectedSku={selectedSku} />;

  const isColorSwatchProduct = options => {
    let isColorSwatch = false;

    for (let i = 0; i < options.length; i++) {
      if (options[i].url !== undefined) {
        isColorSwatch = true;
        break;
      }
    }

    return isColorSwatch;
  };

  const getBuckleFinishChoices = () => {
    const customizationOptions = [];
    productState.customization &&
      productState.customization.customizationOptions &&
      productState.customization.customizationOptions.forEach(element => {
        customizationOptions.push(element.choices);
      });
    return flatten(customizationOptions);
  };

  const optionType = get(
    product,
    'customization.customizationOptions[0].type',
    '',
  );
  const options = { allowUnsafeSymbols: true };
  const sizeGuideHTML = unescape(
    get(product, 'details.sizeGuide', ''),
    options,
  );

  const colorOptionsWithSkusStatus = colorOptions.values.map((item, index) => {
    let availableSkusByColor = [];
    availableSkusByColor = getSizeSkus(productState, index).filter(sku => {
      // return sku && ((sku.inStock && sku.sellable) || sku.backOrder)
      return sku && sku.sellable;
    });
    if (!availableSkusByColor.length) {
      item.areAllSkusOOS = true;
    }
    return item;
  });

  // const isGiftCard = promotions => {
  //   return promotions.some(
  //     promotion =>
  //       promotion.templateHTML &&
  //       GIFT_CARD &&
  //       promotion.templateHTML.toLowerCase().includes(GIFT_CARD.toLowerCase()),
  //   );
  // };

  const filterPromotions = promotions => {
    return promotions
      ? partition(promotions, { showPromoPrice: false })
      : [[], []];
  };

  // const [promotionWithPrice, promotionWithoutPrice] = filterPromotions(
  //   productState.promotions,
  // );

  const [promotionWithoutPrice] = filterPromotions(productState.promotions);

  let sizeClass = 'size-wrapper';
  if (
    (!props.modal.open && !isEmpty(product.addToBagError)) ||
    wishlistValidationErrors?.size
  ) {
    sizeClass += ' form-error';
  }

  const isSkuAddedToWishlist = () => {
    const { wishlist } = props;
    if (
      wishlist &&
      wishlist.items &&
      wishlist.items.length > 0 &&
      selectedSku
    ) {
      for (let i = 0; i < wishlist.items.length; i++) {
        if (wishlist.items[i].skuId === selectedSku.id) {
          return true;
        }
      }
    }
    return false;
  };

  const checkForMedia = currentValue => {
    return currentValue.media;
  };

  const shouldDisplayApplePay = () => {
    if (isApplePaySupported) {
      if (product.replenishable) {
        return product.replenishInterval < 1;
      } else if (selectedSku?.dropShip) {
        return false;
      } else if (
        product?.attributesMap &&
        Object.prototype.hasOwnProperty.call(
          product.attributesMap,
          'beauty Ingredients',
        ) &&
        product.attributesMap['beauty Ingredients'].includes('CBD')
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const hasColorMedia = colorOptions.values.every(checkForMedia);
  const hasSwatchUrls = isColorSwatchProduct(colorOptions.values);
  const hasMoreThanOneColor = colorOptions.values.length > 0;

  const klarnaAmount =
    product?.price?.promotionalPrice || product?.price?.retailPrice;

  const isKlarnaEligible =
    props?.pdpKlarnaMessaging &&
    klarnaAmount <= 20000 &&
    !product?.isChanel &&
    !product?.isCustomizable &&
    !product?.details?.suppressCheckout;

  return (
    <div className="checkout-option__info">
      <div className="gutter-bottom-half">
        {hideFromEditWishlist && !isMobileWebPdpRedesign(props.abTestIds) && (
          <Badges
            preOrder={productState && productState.preOrder}
            isNewArrival={get(productState, 'productFlags.isNewArrival', true)}
            isExclusivelyOurs={get(
              productState,
              'productFlags.isOnlyAtNM',
              false,
            )}
            promotions={{
              promotionWithoutPrice,
              field: 'itemHTML',
            }}
          />
        )}

        {hasSwatchUrls && hasMoreThanOneColor ? (
          <ProductOptionSet
            productId={product.id}
            labelHeading="Select"
            label="color"
            options={colorOptionsWithSkusStatus}
            selectedIndex={selectedColorIndex}
            availableSkus={colorSkus}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredColorIndex}
            onHover={props.setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType=""
            disableCustomDropDown={props.disableCustomDropDown}
            isFromShopTheLook
            isColorTextDropdownEnabled={props.isColorTextDropdownEnabled}
            productCategory={productCategory}
            isDesktop={props.isDesktop}
          />
        ) : null}

        {!hasSwatchUrls && hasMoreThanOneColor && !hasColorMedia ? (
          <ProductOptionSet
            productId={product.id}
            labelHeading="Select"
            label="color"
            options={colorOptionsWithSkusStatus}
            selectedIndex={selectedColorIndex}
            availableSkus={colorSkus}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredColorIndex}
            onHover={props.setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType=""
            disableCustomDropDown={props.disableCustomDropDown}
            hasColorMedia={hasColorMedia}
            isColorTextDropdownEnabled={props.isColorTextDropdownEnabled}
            productCategory={productCategory}
            isDesktop={props.isDesktop}
          />
        ) : null}

        {sizeOptions.values.length > 0 && (
          <div className={sizeClass}>
            <EyeBrow1>
              <span
                className={classnames('size-section', {
                  'size-section-single': sizeOptions.values.length === 1,
                })}
              >
                <span>SIZE:</span>
                {hideFromEditWishlist && !isFromShopTheLook && (
                  <span
                    className="size-guide-link"
                    dangerouslySetInnerHTML={{ __html: sizeGuideHTML }}
                  />
                )}
              </span>
            </EyeBrow1>
            <ProductSize
              productId={product.id}
              labelHeading="Select"
              label="size"
              options={sizeOptions.values}
              selectedIndex={selectedSizeIndex}
              availableSkus={sizeSkus}
              onClick={props.setSelectedIndex}
              hoveredIndex={hoveredSizeIndex}
              onHover={props.setHoveredIndex}
              optionType=""
              optionSettings={{
                label: 'name',
                value: 'index_index',
                selectLabel: 'Select Size',
              }}
              disableCustomDropDown={props.isMobile}
            />
            {/* erree */}
            {(!props.modal.open || isFromShopTheLook) && (
              <ErrorMessage message={product.addToBagError} />
            )}
            <ErrorMessage message={props.wishlistValidationErrors?.size} />
          </div>
        )}
        {hideFromEditWishlist &&
        !isFromShopTheLook &&
        isCustomizationOptionTypePDP() ? (
          <DropDownComponent
            productId={product.id}
            labelHeading="Select"
            label={BUCKLE_FINISH}
            options={getBuckleFinishChoices()}
            selectedIndex={selectedBuckleFinishIndex}
            onClick={props.setSelectedIndex}
            hoveredIndex={hoveredBuckleIndex}
            onHover={props.setHoveredIndex}
            noAvailableSizeSelected={noAvailableSizeSelected}
            optionType={optionType}
            disableCustomDropDown={props.disableCustomDropDown}
            addToBagError={product.addToBagError}
          />
        ) : (
          ''
        )}
      </div>

      {product.replenishable ? (
        <BeautyReplenishment
          onIntervalSelect={props.setReplenishInterval}
          openReplenishmentModal={props.openReplenishment}
          replenishInterval={product.replenishInterval}
        />
      ) : null}

      {hideFromEditWishlist &&
      !isFromShopTheLook &&
      shouldShowPersonalizeSection() &&
      !isCustomizationOptionTypePDP() ? (
        <Personalization product={product} selectedSku={selectedSku} />
      ) : null}

      {isRequiredMonogramNotCustomized() && !isCustomizationOptionTypePDP() ? (
        <div className="personalization-required-wrapper">
          <div className="required-text">
            <EyeBrow1>{PERSONALIZATION_REQUIRED}</EyeBrow1>
          </div>
          <div>
            <BodyB1>{PERSONALIZATION_REQUIRED_TEXT}</BodyB1>
          </div>
        </div>
      ) : null}

      {!product.isZeroDollarProduct &&
        (hideFromEditWishlist && !isFromShopTheLook) && (
          <div className="messages">
            <SkuStatusMessages
              selectedSku={selectedSku}
              perishable={product.perishable}
              selectedSkuQuantity={product.quantity}
              preciousJewelryItem={isPreciousJewelry()}
              isChanel={product.isChanel}
              merchandiseType={product.merchandiseType}
            />
          </div>
        )}
      {product.perishable && (
        <div className="grid-100 tablet-grid-100 mobile-grid-100 perishable-item-section">
          <PerishableMessage perishable={product.perishable} />
          {hideFromEditWishlist ? (
            <PerishableCalendar
              productId={product.id}
              expectedShipDays={get(selectedSku, 'expectedShipDays', null)}
              isWishlist={false}
            />
          ) : (
            <PerishableCalendar
              productId={product.id}
              expectedShipDays={get(selectedSku, 'expectedShipDays', null)}
              deliveryDate={deliveryDate}
              isWishlist
            />
          )}
          {!props.modal.open && (
            <ErrorMessage message={product.addToBagError} />
          )}
        </div>
      )}
      <BossMessage
        selectedSku={selectedSku}
        startDate={boss.startDate}
        endDate={boss.endDate}
        message={boss.message}
      />
      <div id={`productButtons_${product.id}`} className="gutter-bottom-half">
        {isPreciousJewelry() && (
          <div className="precious-jewellry">
            <span className="precious-jewelry-message">
              {PRECIOUS_JEWELRY_PAYPAL_MESSAGE}
            </span>
          </div>
        )}
        {hideFromEditWishlist &&
        !isFromShopTheLook &&
        isRequiredMonogramNotCustomized() &&
        !isCustomizationOptionTypePDP() ? (
          <div className="quntity-addtobag">
            {quantityEnabled && quantityEnabled === true && (
              <ProductQuantity
                product={product}
                selectedSku={selectedSku}
                isGroupPDP={props.isGroupPDP}
                disableCustomDropDown={props.isMobile}
              />
            )}
            <PersonalizeItemButton
              product={product}
              selectedSku={selectedSku}
            />
          </div>
        ) : (
          <Fragment>
            {hideFromEditWishlist && !product.isZeroDollarProduct && (
              <>
                <div className="quntity-addtobag">
                  {quantityEnabled &&
                    quantityEnabled === true &&
                    !isFromShopTheLook && (
                      <ProductQuantity
                        product={product}
                        selectedSku={selectedSku}
                        isGroupPDP={props.isGroupPDP}
                        disableCustomDropDown={props.isMobile}
                      />
                    )}
                  {addToBagButton}
                </div>
                {props.isMobile && isKlarnaEligible && (
                  <KlarnaMessaging
                    price={klarnaAmount}
                    selectedSku={selectedSku}
                  />
                )}
                {isMobileWebPdpRedesign(props.abTestIds) && props.isMobile && (
                  <div className="variation-b">
                    <AffirmCta product={product} />
                  </div>
                )}
              </>
            )}
            {shouldDisplayApplePay() && (
              <ApplePay
                handleApplePay={handleApplePay}
                paymentLoading={props.applePayData.paymentLoading}
                appleUserData={props.appleUserData}
                applepayData={props.applePayData}
                productDetails={product}
                applePayAuthorized={applePayAuthorized}
                isApplePayAuthorized={isApplePayAuthorized}
                productPrice={product?.price?.retailPrice}
                selectedSku={selectedSku}
                getUserData={getUserData}
                perishableDeliveryByDate={props?.perishableDeliveryDate}
              />
            )}
            <div className="stl-link-section">
              {isFromShopTheLook && (
                <a className="stl-go-to-item-page" href={canonicalUrl}>
                  <EyeBrow7>{GO_TO_ITEM_PAGE}</EyeBrow7>
                </a>
              )}
              {props.isDomestic &&
                props.showAddToWishlist &&
                hideFromEditWishlist &&
                !product.isZeroDollarProduct &&
                (!isSkuAddedToWishlist() ? (
                  <SaveToWishlistLink
                    selectedSku={selectedSku}
                    productId={product.id}
                  />
                ) : (
                  <span className="saveToWishlist">
                    <EyeBrow4>Saved</EyeBrow4>
                  </span>
                ))}
            </div>

            {/* This dropdown is a placeholder for
            Notify me when this is back in stock @ShopRunner */}
            {hideFromEditWishlist && !isFromShopTheLook && (
              <div className="notification-widget">
                <DropDownComponent
                  productId={product.id}
                  labelHeading="Select"
                  label="size"
                  options={sizeOptions.values}
                  selectedIndex={selectedSizeIndex}
                  availableSkus={sizeSkus}
                  onClick={props.setSelectedIndex}
                  hoveredIndex={hoveredSizeIndex}
                  onHover={props.setHoveredIndex}
                />
              </div>
            )}

            {product.isZeroDollarProduct && (
              <Fragment>
                <div className="gwpPromoText">
                  <EyeBrow2>{GWP_TEXT}</EyeBrow2>
                </div>
                <ShopNowButton
                  designer={product.designer}
                  zeroDollarPageAllBeautyLink={zeroDollarPageAllBeautyLink}
                />
              </Fragment>
            )}
            {product.replenishable && product.details.shortDesc ? (
              <div className="item-promo">
                <div className="item-promo-text">
                  <span className="inquery-label">{BEAUTY_SPECIALIST}</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: unescape(product.details.shortDesc),
                    }}
                  />
                </div>
                <span className="outer-border" />
              </div>
            ) : null}
          </Fragment>
        )}
        {props.showAddToFavorites && <FavComponent productId={product.id} />}
        {props.isDomestic &&
          props.showFindInStore &&
          (props.suppressBOPSForParentheticalToggle
            ? parentheticalCharge <= 0
            : true) && (
            <div
              id={product.id + appendKey}
              className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent"
            >
              <BopsButton
                product={product}
                selectedSku={selectedSku}
                skuMediaUrl={get(selectedOptionMedia, 'main.thumbnail.url', '')}
              />
            </div>
          )}
        {props.showGiftNow && !isRequiredMonogram() && giftNowButton}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    pdpOptimisationToggle: state.toggles.PDP_OPTIMISATION,
    suppressBOPSForParentheticalToggle:
      state.toggles.SUPPRESS_BOPS_FOR_PARENTHETICAL,
    showAddToFavorites: false, // state.toggles.PDP_FAVORITE,
    showAddToWishlist: state.toggles.BG_PHASE_2_WISHLIST,
    showFindInStore: state.toggles.PDP_FIND_IN_STORE,
    showGiftNow: state.toggles.PDP_GIFTNOW,
    isMobile: state.device.isMobile,
    modal: state.modal,
    wishlist: state?.wishlist?.wishlist,
    release2: state.toggles.BG_PHASE_2_RELEASE_2,
    zeroDollarPageAllBeautyLink:
      state.productCatalog.zeroDollarPageAllBeautyLink,
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    api: get(state, 'api', {}),
    isNewCartCheckoutEnabled: isNewCartCheckoutEnabled(state),
    isColorTextDropdownEnabled: state.toggles.DROPDOWN_TEXT_BEAUTY,
    isDesktop: state.device.isDesktop,
    userData: state.user,
    applePayData: get(state, 'productCatalog.applePay', {}),
    appleUserData: get(state, 'productCatalog.applePay.appleUserData', {}),
    applePayToggle: state.toggles.APPLE_PAY_PDP,
    perishableDeliveryDate: state.productCatalog?.product?.deliveryDate || '',
    abTestIds: get(state, 'abTestsOpt', []),
    pdpKlarnaMessaging: get(state, 'toggles.PDP_KLARNA_MESSAGING', false),
  };
};

const mapDispatchToProps = (dispatch, { product: { id: productId } }) => ({
  setSelectedIndex: (...args) => dispatch(setSelectedIndex(productId, ...args)),
  setHoveredIndex: (...args) => dispatch(setHoveredIndex(productId, ...args)),
  setReplenishInterval: (...args) =>
    dispatch(setReplenishInterval(productId, ...args)),
  openReplenishment: () => dispatch(openReplenishment()),
  checkIfApplePayIsEligible: () => dispatch(checkIfApplePayIsEligible()),
  addToBag: (...args) => dispatch(addToBag(...args)),
  showAddToBagError: (...args) =>
    dispatch(showAddToBagError(productId, ...args)),
  validatePDP: (...args) => dispatch(validatePDP(...args)),
  screenReaderAnnouncer: (...args) => dispatch(screenReaderAnnouncer(...args)),
  clearError: () => dispatch(clearError(productId)),
  getUserData: () => dispatch(getUserData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutOptionInfo);
