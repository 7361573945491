import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import { promiseAllSettled } from '../../utilities/promiseAllSettled';
import { Channels, CMSParams } from '../constants/constants';
import {
  LOADING_CMS_CONTENT,
  REJECTED_CMS_CONTENT,
  RESOLVED_CMS_CONTENT,
  LOADING_CMS_GLOBAL,
  REJECTED_CMS_GLOBAL,
  RESOLVED_CMS_GLOBAL,
  LOADING_MODAL_CONTENT,
  RESOLVED_MODAL_CONTENT,
  REJECTED_MODAL_CONTENT,
  SET_ACTIVE_ENTRY_ID,
} from '../constants/action-types';

const getServiceUrl = (queryParams, isMobileVersionAvailable, usePreview, isCMSPreview, cmsTimeStamp) => {
  const { query, channel, isInternational, contentId } = queryParams;
  const apiPath = NMConfig.API_CONTENT_MGT_V2;
  const previewApiPath = NMConfig.API_CONTENT_PREVIEW;
  let url = isCMSPreview ? `${previewApiPath}/contentPreview?cPath=${query}` : `${apiPath}/content?cPath=${query}`;
  url = url.concat('&brand=BG');
  url = cmsTimeStamp ? url.concat(`&schedule=${cmsTimeStamp}`) : url;
  url = isMobileVersionAvailable ? url.concat(`&channel=${channel}`) : url;
  url = usePreview || isCMSPreview ? url.concat('&preview=true') : url;
  url = isInternational ? url.concat('&isInternational=International') : url;
  return contentId ? `${NMConfig.API_ENTRY_CONTENT}?entryId=${contentId}` : url;
};

const getResponseData = response => {
  const resolvedData = response.filter(
    data => data.value && data.value.data.length,
  );

  return resolvedData[0].value.data;
};

const getEntries = (updatedContentUrl, requestApi, v2Lambdaheaders) => {
  return promiseAllSettled([requestApi(updatedContentUrl, v2Lambdaheaders)]);
};

/**
 * @function getChannel
 * @param {Boolean} isMobilePhone
 * @returns {String}
 */
const getChannel = isMobilePhone => {
  const { MOBILE, DESKTOP } = Channels;

  return isMobilePhone ? MOBILE : DESKTOP;
};

/**
 * @function getCMSContent
 * @param {String} query
 * @returns {AsyncFunc}
 */ 
export const getCMSContent = (query, isCMSPreview, cmsTimeStamp) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_CONTENT });

    try {
      const state = getState();
      const { device, toggles } = state;

      const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
      const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
      const isCFModelV2 = get(toggles, 'CF_MODEL_V2', false);
      const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
      const isMobilePhone = get(device, 'isMobilePhone', false);
      const usePreview =
        state.api.requestContext[CMSParams.CMS_PREVIEW] &&
        (state.api.requestContext.isInternalIp ||
          isEmpty(state.api.requestContext.TRUE_CLIENT_IP));

      const channel = getChannel(isMobilePhone);

      const v1Lambdaheaders = {};
      const v2Lambdaheaders = {};
      const localeValue = get(state, 'locale.countryCode', 'US');
      const isInternational = localeValue !== 'US';
      const queryParams = { channel, query, isInternational };

      if (usePreview) {
        v1Lambdaheaders['Cache-Control'] = 'max-age=0';
        v2Lambdaheaders['Cache-Control'] = 'max-age=0';
      }
      if (addApiKeyToHeader && global) {
        if (global.contentMgtV1_SSMKey) {
          v1Lambdaheaders['x-api-key'] = global.contentMgtV1_SSMKey;
        }
        if (global.contentMgtV2_SSMKey) {
          v2Lambdaheaders['x-api-key'] = global.contentMgtV2_SSMKey;
        }
      }
      const requestApi = (url, headers) =>
        httpWithLogging(state, timeout).get(url, { headers });
      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        usePreview,
        isCMSPreview,
        cmsTimeStamp
      );

      const content = await getEntries(
        updatedContentUrl,
        requestApi,
        v2Lambdaheaders,
      );
      const response = getResponseData(content);
      dispatch({
        type: RESOLVED_CMS_CONTENT,
        payload: response,
      });

      return response;
    } catch (err) {
      dispatch({ type: REJECTED_CMS_CONTENT });

      return err;
    }
  };
};

export const getCMSGlobal = query => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_GLOBAL });

    try {
      const state = getState();
      const { device, toggles } = state;

      const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
      const isMobilePhone = get(device, 'isMobilePhone', false);
      const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);

      const usePreview =
        state.api.requestContext[CMSParams.CMS_PREVIEW] &&
        (state.api.requestContext.isInternalIp ||
          isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
      const { MOBILE, DESKTOP } = Channels;
      const channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
      const headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};
      if (addApiKeyToHeader && global && global.contentMgtV2_SSMKey) {
        headers['x-api-key'] = global.contentMgtV2_SSMKey;
      }
      const localeValue = get(state, 'locale.countryCode', 'US');
      const isInternational = localeValue !== 'US';
      const queryParams = { channel, query, isInternational };
      const requestApi = url =>
        httpWithLogging(state, timeout).get(url, { headers });

      const updatedContentUrl = getServiceUrl(queryParams, true, usePreview);
      const content = await requestApi(updatedContentUrl);
      const response = content.data;

      dispatch({
        type: RESOLVED_CMS_GLOBAL,
        payload: response,
      });

      return response;
    } catch (err) {
      dispatch({ type: REJECTED_CMS_GLOBAL });

      return err;
    }
  };
};

export function getModalPopup(contentId) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_MODAL_CONTENT });

    try {
      const state = getState();
      const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
      const isMobileVersionAvailable = get(
        state,
        'toggles.MOBILE_VERSION',
        false,
      );
      const queryParams = { contentId };
      const requestApi = url => httpWithLogging(state, timeout).get(url);
      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        false,
      );

      const response = await requestApi(updatedContentUrl);
      const modalContent = response.data || [];

      dispatch({
        type: RESOLVED_MODAL_CONTENT,
        payload: modalContent,
      });

      return modalContent;
    } catch (err) {
      dispatch({ type: REJECTED_MODAL_CONTENT });

      return err;
    }
  };
}

export function setActiveEntryId(entryId) {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_ENTRY_ID,
      payload: entryId,
    });
  };
}
