import { stringify } from 'query-string';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import log from 'server/utilities/logger';
import reactCookie from 'react-cookie';

export const TOGGLE_SEARCH_PANEL = 'TOGGLE_SEARCH_PANEL';
export const OPEN_SEARCH_PANEL = 'OPEN_SEARCH_PANEL';
export const CLOSE_SEARCH_PANEL = 'CLOSE_SEARCH_PANEL';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const AUTO_SUGGESTION_LIST = 'AUTO_SUGGESTION_LIST';
export const CLEAR_AUTO_SUGGESTION_LIST = 'CLEAR_AUTO_SUGGESTION_LIST';
export const ENDECA_SUGGESTION_LIST = 'ENDECA_SUGGESTION_LIST';
export const CLEAR_SUGGESTION_LIST = 'CLEAR_SUGGESTION_LIST';

export const types = {
  SET_PLACEHOLDER_VALUE: 'SET_PLACEHOLDER_VALUE',
  SET_MOBILE_SEARCH_TERM: 'SET_MOBILE_SEARCH_TERM',
};

export function toggleSearchPanel() {
  return dispatch => dispatch({ type: TOGGLE_SEARCH_PANEL });
}

export function openSearchPanel() {
  return dispatch => dispatch({ type: OPEN_SEARCH_PANEL });
}

export function closeSearchPanel() {
  return dispatch => dispatch({ type: CLOSE_SEARCH_PANEL });
}

export function updateSearchTerm(searchTerm, clearPlaceholder, vertexAiToggle) {
  return dispatch => {
    dispatch({ type: UPDATE_SEARCH, searchTerm });
    if (searchTerm.length > 2) {
      getSearchAutoSuggestions(searchTerm, vertexAiToggle)(dispatch);
    }
    if (!searchTerm && clearPlaceholder) {
      setPlaceHolderValueToEmpty()(dispatch);
    }
  };
}

export function getSearchAutoSuggestions(keyword = '', vertexAiToggle) {
  return dispatch => {
    const visitorId = reactCookie.load('cstmr')?.customerId || '';
    if (keyword.length > 0) {
      resetEndecaSuggestions()(dispatch);
      const params = {
        account_id: 5097,
        auth_key: '1xbee7b3xls8sgv5',
        domain_key: 'bergdorfgoodman',
        request_id: Math.round(Math.random() * 10e12),
        request_type: 'suggest',
        q: keyword,
        url: location.href,
      };
      let apiUrl = NMConfig.API_AUTO_SUGGESTION;
      if (vertexAiToggle) {
        const data = {
          domain_key: 'bergdorfgoodman',
          q: keyword,
          visitorId,
        };
        apiUrl = NMConfig.API_GRS_AUTO_SUGGEST;
        return axios
          .post(`${apiUrl}`, data)
          .then(successResponse => {
            const { data } = successResponse;
            const payload = {
              suggestions: data?.suggestions?.completionResults,
              completionAttributionToken: data?.attributionToken,
            };

            return dispatch({
              type: AUTO_SUGGESTION_LIST,
              payload,
            });
          })
          .catch(err => {
            log.error(err);
          });
      }
      return axios
        .get(`${apiUrl}?${stringify(params)}`)
        .then(successResponse =>
          dispatch({
            type: AUTO_SUGGESTION_LIST,
            payload: successResponse?.data?.response?.suggestions,
          }),
        )
        .catch(err => {
          log.error(err);
        });
    }
    return null;
  };
}

export function clearAutosuggestion() {
  return dispatch => {
    dispatch({ type: CLEAR_AUTO_SUGGESTION_LIST });
  };
}

export function clearSuggestionsList() {
  return dispatch => {
    dispatch({ type: CLEAR_SUGGESTION_LIST });
  };
}

export function setSearchTermToEmpty(
  searchTerm,
  clearPlaceholder,
  vertexAiToggle,
) {
  return dispatch =>
    updateSearchTerm(searchTerm, clearPlaceholder, vertexAiToggle)(dispatch);
}

export function setSearchTermValueFromPlaceholder(
  currentPlaceholder = '',
  currentValue = '',
  vertexAiToggle,
) {
  return dispatch => {
    if (currentPlaceholder && !currentValue) {
      updateSearchTerm(currentPlaceholder, true, vertexAiToggle)(dispatch);
    }
  };
}

export function setPlaceholderValue(placeholderValue) {
  return dispatch =>
    dispatch({ type: types.SET_PLACEHOLDER_VALUE, placeholderValue });
}

export function setPlaceHolderValueToEmpty() {
  return dispatch => setPlaceholderValue('')(dispatch);
}

export function setMobileTextBoxValue(searchTerm) {
  return dispatch =>
    dispatch({
      type: types.SET_MOBILE_SEARCH_TERM,
      mobileSearchTerm: searchTerm,
    });
}

/* export function updateEndecaSearchTerm(searchTerm) {
  return dispatch => {
    getEndecaSuggestions(searchTerm);
  };
} */

export function getEndecaSuggestions(keyword = '') {
  return dispatch => {
    if (keyword.length > 0) {
      resetEndecaSuggestions()(dispatch);
      /* const state = getState();
      const RECOMMENDATION_API_TIMEOUT = 10000;
      const headers = {};
      const requestApi = httpWithLogging(state, RECOMMENDATION_API_TIMEOUT); */
      const params = { term: keyword };
      return axios
        .get(`${NMConfig.API_ENDECA_TYPEAHEAD}?${stringify(params)}`)
        .then(successResponse =>
          dispatch({
            type: ENDECA_SUGGESTION_LIST,
            payload: successResponse.data,
          }),
        )
        .catch(err => {
          log.error(err);
        });
    }
    return null;
  };
}

export function resetEndecaSuggestions() {
  return dispatch => {
    dispatch({ type: ENDECA_SUGGESTION_LIST, payload: [] });
  };
}
