import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { isNewHomePage } from '../../../../utilities/is-new-home-page';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from '../../contentItem/contentItem';
import { getTags } from '../../utils';
import { defaultTrackingTags } from '../../../constants/constants';

const CN = 'bubble-visual-nav';

const scrollValue = 1.4;

export default class SlickVisualNav extends Component {
  constructor(props) {
    super(props);
    this.state = { toScroll: scrollValue };
  }

  getSliderSettings() {
    const { cmsContentItem, isNewHomePage, newVNToggle } = this.props;
    const { slides } = cmsContentItem.fields;

    return {
      arrows: true,
      className: `${CN}${isNewHomePage ? ' new-home-page-nav' : ''}${
        newVNToggle ? ' new-visual-nav' : ''
      }`,
      infinite: false,
      slidesToShow: isNewHomePage ? 4.3 : 6,
      slidesToScroll: isNewHomePage ? this.state.toScroll : 1,
      draggable: true,
      responsive: [
        {
          breakpoint: 450,
          settings: {
            arrows: false,
            slidesToShow: isNewHomePage ? 1.3 : 3,
            slidesToScroll: 1,
            touchMove: !isNewHomePage,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: isNewHomePage ? 2.3 : 3,
            touchMove: !isNewHomePage,
          },
        },
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: isNewHomePage ? 3.3 : 6,
          },
        },
      ],
      beforeChange: (prev, current) => {
        if (isNewHomePage) {
          const brp = get(this.slider, 'state.breakpoint');
          if (brp !== 376) {
            const brpData = this.slider.props.responsive.find(
              item => item.breakpoint === brp,
            );
            const slidesToShow = brpData
              ? brpData.settings.slidesToShow
              : get(this.slider, 'props.slidesToShow');
            // set after first scroll or set after one back scroll:
            if (
              prev === 0 ||
              (Math.floor(prev) + Math.ceil(slidesToShow) === slides.length &&
                Math.floor(prev) !== Math.floor(current))
            ) {
              this.setState({ toScroll: 1 });
            }
            // set initial scroll for back scroll and for the first slide or set for the last scroll:
            if (
              (Math.floor(prev) === 2 && Math.floor(current) === 1) ||
              (Math.floor(current) === 0 && prev !== scrollValue) ||
              Math.floor(current) + 1 + Math.ceil(slidesToShow) ===
                slides.length
            ) {
              this.setState({ toScroll: scrollValue });
            }
          }
        }
      },
    };
  }

  render() {
    const { cmsContentItem, parentTrackingTags } = this.props;
    const { slides } = cmsContentItem.fields;

    // Set up Tracking Tags
    const trackingTags = get(cmsContentItem, 'fields.trackingTags');
    const currentTags = getTags(trackingTags);
    const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);

    return (
      <Slider
        ref={slider => {
          this.slider = slider;
        }}
        {...this.getSliderSettings()}
      >
        {slides.map(slide => (
          <RenderContentItem
            cmsContentItem={slide}
            key={slide.sys.id}
            parentTrackingTags={mergedTrackingTags}
          />
        ))}
      </Slider>
    );
  }
}

SlickVisualNav.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};
