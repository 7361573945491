import React, { useEffect } from 'react';
import './styles.scss';

const CheckIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 12.9883L5.58333 10.2383L4.41666 11.405L8.33333 15.3217L16.4167 7.23833L15.25 6.07166L8.33333 12.9883Z"
      fill="white"
    />
  </svg>
);

const Toast = ({ message, onClose, duration = 5000, fullWidth }) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  return (
    <div className={`toast-container ${fullWidth ? 'fullwidth' : ''}`}>
      <CheckIcon />
      <span className="message">{message}</span>
      <button type="button" className="close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Toast;
