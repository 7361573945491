import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { isNewHomePage } from '@bgo-ui/common/client/utilities/is-new-home-page';
import { getTags } from '../utils';
import { defaultTrackingTags } from '../../constants/constants';
import SlickVisualNav from './slickVisualNav/slickVisualNav';
import SwiperVisualNav from './swiperVisualNav/swiperVisualNav';
import './visualNav.scss';

const DumbVisualNav = props => {
  const {
    cmsContentItem,
    parentTrackingTags,
    isNewCarousel,
    isNewHomePage,
    isPlpVisualNav,
    isLssShopsCarousel,
    cmsContent,
    isHomePage,
    newVNToggle,
  } = props;

  // Set up Tracking Tags
  const trackingTags = get(cmsContentItem, 'fields.trackingTags');
  const currentTags = getTags(trackingTags);
  const mergedTrackingTags = merge({}, parentTrackingTags, currentTags);
  const shopsBubble = get(cmsContentItem, 'fields.name', {});
  const lssShopsData = get(cmsContentItem, 'fields.slides', []);
  const isPlpVisualNavData = !!(
    cmsContent?.fields?.l1Layouts?.[0]?.fields?.components?.[0]?.contentType ===
      'visualNav' &&
    cmsContent?.fields?.l1Layouts?.[0]?.fields?.components?.[0]?.fields?.slides
      ?.length >= 5
  );
  const showPLPVisualNav = isPlpVisualNav && isPlpVisualNavData;
  const showLssShops =
    isLssShopsCarousel &&
    shopsBubble === 'Rectangle Bubbles - New Bubbles' &&
    lssShopsData?.length > 0;
  let Carousel = SlickVisualNav;
  if (isNewCarousel && !newVNToggle) {
    Carousel = SwiperVisualNav;
  }

  return (
    <Carousel
      cmsContentItem={cmsContentItem}
      mergedTrackingTags={mergedTrackingTags}
      isNewHomePage={isNewHomePage}
      plpVisualNav={showPLPVisualNav}
      lssShopsCarousel={showLssShops}
      homePage={isHomePage === '/'}
      newVNToggle={newVNToggle}
    />
  );
};

DumbVisualNav.defaultProps = {
  parentTrackingTags: defaultTrackingTags,
};

const mapStateToProps = state => ({
  isNewHomePage: isNewHomePage(state),
  isNewCarousel: state?.toggles?.NEW_HP_CAROUSEL,
  cmsContent: get(state, 'cms.entries[0]', {}),
  isPlpVisualNav: state?.toggles?.VISUAL_NAV_PLP,
  isLssShopsCarousel: state?.toggles?.LSS_SHOP_CAROUSEL,
  isHomePage: state?.page?.location?.pathname,
  newVNToggle: state?.toggles?.NEW_PLP_VIS_NAV,
});

export default connect(mapStateToProps)(DumbVisualNav);
