import qs from 'query-string';

export function getParams(location) {
  return location ? qs.parse(location.search) : '';
}

export function updateParams(router, params) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params })}`,
  });
}

export function updateParamsWithStoreName(router, params, storeName) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params, storeName })}`,
  });
}

export function setParams(router, params) {
  const location = router.getCurrentLocation();
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...params })}`,
  });
}

export function updateParamsWithPathname(router, pathname, params) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  return router.push({
    pathname,
    search: `?${qs.stringify({ ...search, ...params })}`,
  });
}

export function removeHyphen(string) {
  return string ? string.replace(/-/g, '') : '';
}

export function replaceSpaceWithHyphen(string) {
  return string ? string.replace(/\s/g, '-') : '';
}

export function replaceSpaceWithHyphenAndEncode(string) {
  const string1 = removeHyphen(string);
  const string2 = replaceSpaceWithHyphen(string1);
  const string3 = string2.replace(/[^a-zA-Z-]/g, '');
  return string3 ? encodeURIComponent(string3) : '';
}

export const keysToExclude = [
  'lsc',
  'page',
  'navpath',
  'facet',
  'sizesFiltered',
  'sortBy',
  'priorityProdId',
  'scroll',
  'src',
  'source',
  'seoToggle',
];
