import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import LinkedData from 'shared/components/LinkedData/linkedData';
import GranifyScript from 'shared/components/Granify/granify';
import {
  setMetaInfo,
  setPageId,
  setPageVisited,
  PAGE_ID_HOME,
} from 'shared/actions/actions-page';
import { getParams } from 'client-utils/utilities-router';
import { getCMSContent } from '../../actions/get-cms-content';
import Content from '../../components/content/content';
import ContentItem from '../../components/contentItem/contentItem';
import {
  ECMHOMEMETATITLE,
  ECMHOMEMETADESCRIPTION,
  ECMHOMEMETAKEYWORDS,
  ECMHOMEMETATAGS,
  ECMHOMERELLINKS,
  ECMHOMECANONICALURL,
  ECMHOMEJSONLD,
} from '../../../ecm/constants';
import { isNewHomePageEnabled } from '../../../utilities/is-new-home-page';
import { NEW_HOMEPAGE_PATH } from '../../constants/constants';
import ScrollToTopButton from '../../../common/components/ScrollButton/scrollButton';
import './home.scss';

class HomePage extends Component {
  UNSAFE_componentWillMount() {
    const {
      locale,
      apiStatus,
      location: { pathname },
      isNewHomePage,
      internationalToggle,
      countryCode,
    } = this.props;
    const metaObj = {
      metaTitle: ECMHOMEMETATITLE,
      metaDescription: ECMHOMEMETADESCRIPTION,
      metaKeywords: ECMHOMEMETAKEYWORDS,
      metaTags: ECMHOMEMETATAGS,
      relLinks: ECMHOMERELLINKS,
      canonicalUrl: `${ECMHOMECANONICALURL}${
        get(locale, 'countryCode', '').toLowerCase() !== 'us'
          ? get(locale, 'localeUrl', '')
          : ''
      }`,
    };
    this.props.setMetaInfo(metaObj);
    this.props.setPageId(PAGE_ID_HOME);
    this.props.setPageVisited({
      event_name: 'pageLoad',
      page_definition_id: 'home',
      page_name: 'Homepage',
      page_template: 'home',
      page_type: 'home',
      page_version: 'test',
    });
    if (pathname && shouldLoad(apiStatus)) {
      const shouldReplacePath = pathname === '/' && isNewHomePage;
      let cmsPath = shouldReplacePath ? NEW_HOMEPAGE_PATH : pathname;
      const hasResponsiveContent =
        this.props.isNewHomePage && !this.props.isMobilePhone;
      if (internationalToggle && countryCode !== 'US') {
        cmsPath = '/';
      }
      const urlParams = new URLSearchParams(this.props.location?.search);
      const CMS_Preview = urlParams.get('CMS_Preview');
      const CMS_Schedule = urlParams.get('CMS_Schedule');
      this.props.dispatch(getCMSContent(cmsPath, CMS_Preview === 'true', CMS_Schedule));
    }
  }

  componentDidMount() {
    const { expDaysForOSP, associnfoPlpToggle } = this.props;
    const { associatePin, channel, osp_lxts } = getParams(this.props.location);
    const honorTimestamp = osp_lxts || 0;
    const currentTimeStamp = new Date().getTime();
    const validFormat = new Date(parseInt(honorTimestamp, 10)).getTime() > 0;
    const validTime =
      validFormat && parseInt(honorTimestamp, 10) > currentTimeStamp;

    if (associnfoPlpToggle && associatePin && channel && validTime) {
      const numberOfDays = expDaysForOSP || 14;
      const nowPlusDays = new Date(
        Date.now() + numberOfDays * 24 * 60 * 60 * 1000,
      );
      cookie.save('assocInfo', `${associatePin}:${channel}`, {
        path: '/',
        domain: `${window.location.hostname}`,
        expires: nowPlusDays,
      });
    }
  }

  render() {
    const {
      cmsContent,
      isNewHomePage,
      sessionExpired,
      validPromo,
      scrollTopTopEnabled,
    } = this.props;
    const placement = 'Main';

    if (!cmsContent.length) return null;

    return isNewHomePage ? (
      <Fragment>
        <LinkedData data={ECMHOMEJSONLD} />
        <div className="homepage homepage--new">
          <ContentItem
            cmsContentItem={cmsContent[0]}
            placement={placement}
            validPromo={validPromo}
            sessionExpired={sessionExpired}
          />
          {scrollTopTopEnabled && <ScrollToTopButton />}
        </div>
        <GranifyScript pageType="home" />
      </Fragment>
    ) : (
      <Fragment>
        <LinkedData data={ECMHOMEJSONLD} />
        <Content />
        <GranifyScript pageType="home" />
        {scrollTopTopEnabled && <ScrollToTopButton />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  apiStatus: get(state.api, 'cms_content', {}),
  cmsContent: get(state, 'cms.entries'),
  isMobilePhone: get(state, 'device.isMobilePhone'),
  isNewHomePage: isNewHomePageEnabled(state),
  locale: get(state, 'locale', {}),
  sessionExpired: get(state, 'user.sessionExpired', true),
  validPromo: get(
    state,
    'navigation.segmentSailo.eliteCustomer.validPromo',
    false,
  ),
  internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
  countryCode: get(state, 'locale.countryCode', 'US'),
  scrollTopTopEnabled: get(state, 'toggles.RETURN_2TOP_BTN', false),
  associnfoPlpToggle: get(state, 'toggles.ASSOCINFO_HP_PLP', false),
});

const mapDispatchToProps = {
  setMetaInfo,
  setPageId,
  setPageVisited,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);
